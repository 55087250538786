.off-canvas-wrapper {
    font-family: sans-serif;
    &.append {
        .off-canvas-content {
            min-height: 100vh;
            padding: $global-padding;
            .breadcrumbs li {
                color: $white;
            }
            .page-title-admin {
                margin-bottom: $global-margin/2;
                color: $white;
                font-family: sans-serif;
                .backPre {
                    margin-bottom: 0;
                    vertical-align: middle;
                }
            }
        }
    }
}

.adminArea {
    background-color: $white;
    padding: $global-padding*2;
    border-radius: $global-radius;
    margin-bottom: $global-margin*2;
    .end {
        margin-bottom: 0;
    }
}

// 區塊列表
.blockItem {
    position: relative;
    padding: $global-padding;
    border: 1px solid $medium-gray;
    margin-bottom: $global-margin;
    border-radius: $global-radius;
    .controlArea {
        position: absolute;
        right: 1rem;
        top: 1rem;
    }
    ul {
        border-bottom: 1px dotted $medium-gray;
        padding-bottom: $global-padding;
        span {
            display: inline-block;
            color: $dark-gray;
            width: 100px;
        }
    }
}

.blocklist {
    // border: 1px solid $medium-gray;
    border-radius: $global-radius;
    margin-bottom: $global-margin/2;
    background: $light-gray;
    .massageTitle {
        margin-bottom: 0;
        font-size: 150%;
    }
    hr {
        margin: $global-margin/2 0;
    }
    .info {
        background: $white;
        border-radius: $global-radius 0 0 $global-radius;
        border-right: 1px solid $medium-gray;
        padding: $global-padding*2;
        dl {
            margin: 0;
            line-height: $global-lineheight
        }
        dt {
            color: $dark-gray;
            margin-bottom: 0;
        }
        dd {
            margin-bottom: $global-margin;
        }
        ul {
            span {
                display: inline-block;
                color: $dark-gray;
                width: 100px;
            }
        }
    }
    .control {
        a {
            margin: 0 $global-margin/2;
            font-size: 200%;
            &.delete {
                color: get-color(alert);
                &:hover {
                    color: scale-color(get-color(alert), $lightness: -14%);
                }
            }
        }
        img {
            width: 32px;
        }
    }
}

.off-canvas-top {
    padding: $global-padding;
    .logo {
        img {
            max-width: 65%;
        }
    }
}

.menu {
    &.append-admin {
        a {
            border-bottom: 1px solid $autostarLight;
            color: $black;
            background-color: $white;
            i {
                color: $autostar;
                font-size: $global-font-size*1.4;
                vertical-align: middle;
                margin-right: $global-margin*0.2;
            }
            &:hover {
                background-color: $autostarLight;
                -webkit-transition: background-color 300ms linear;
                -ms-transition: background-color 300ms linear;
                transition: background-color 300ms linear;
            }
            &.active {
                background-color: $autostar;
                color: $white;
                border-color: $autostar;
                i {
                    color: $white;
                }
            }
        }
    }
}

// body {
//  font-family: sans-serif;
// }
.category {
    &.atCategoryManage {
        ul {
            margin-left: $global-margin*0.5;
            li {
                background-position-y: 22px;
                a {
                    margin-left: $global-margin;
                }
                &:last-child {
                    background-position-y: -27px;
                }
            }
        }
    }
    .level-1 {
        margin-left: 0;
    }
    ul {
        list-style: none;
        margin-left: $global-margin*0.3;
        background-image: url('../img/listDot.png');
        background-repeat: repeat-y;
        li {
            background-image: url('../img/listDot.png');
            background-repeat: repeat-x;
            background-position-y: 13px;
            padding-left: 1rem;
            label {
                background: $white;
            }
            &:last-child {
                background-image: url('../img/listDotLast.png');
                background-repeat: no-repeat;
                background-position-y: -36px;
                background-position-x: -50px;
            }
        }
        [type=checkbox] {
            margin-bottom: 0;
        }
        div {
            border: 1px solid $medium-gray;
            padding: $global-padding/2 $global-padding;
            margin-bottom: $global-margin/2;
            background-color: $white;
            border-radius: 4px;
            &.disabled {
                background-color: $light-gray;
            }
            i {
                font-size: 150%;
            }
        }
    }
}

.uploadImgArea {
    .imagePreview {
        &.atSetup {
            width: 25%;
        }
        cursor: pointer;
        &.main {
            .thumbnail {
                border-color: $autostar;
            }
        }
        position: relative;
        .deleteImg {
            position: absolute;
            left: 2px;
            top: 2px;
        }
    }
}

i {
    &.alert {
        color: get-color(alert);
        &:hover {
            color: scale-color(get-color(alert), $lightness: -14%);
        }
    }
}

.table-scroll {
    &.append {
        white-space: nowrap;
    }
}

table {
    &.append {
        width: 100%;
        tr.item {
            border-bottom: 1px dotted $medium-gray;
        }
        .category span {
            margin-bottom: $global-margin*0.1;
            font-size: 0.65rem;
            &:last-child {
                margin-bottom: 0;
            }
        }
        .table-thumbnail,
        [type=checkbox] {
            margin: 0;
        }
        .table-thumbnail {
            max-width: 80px;
        }
        i {
            font-size: 200%;
        }
    }
}

.help-text {
    &.append {
        color: #cc4b37;
        i {
            font-size: 200%;
            vertical-align: middle;
        }
    }
}

@media screen and (max-width: 39.9375em) {
    .category {
        ul {
            font-size: 0.85rem;
            &.atCategoryManage {
                margin-left: $global-margin*1;
            }
        }
    }
    .adminArea {
        padding: $global-padding*1;
    }
    .blockItem {
        ul {
            span {
                display: block;
            }
        }
    }
}