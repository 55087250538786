// 通用
@mixin allShadow($imgShadowColor) {
    border: 1px solid $medium-gray;
    box-shadow: 3px 3px 0 rgba($imgShadowColor, 0.1);
}

body {
    &.append {
        background-color: $black;
        background-image: url('../img/bodyBg.png');
        background-position: 0 -35px;
    }
}

.orbit {
    &.append {
        min-height: 100vh;
        .orbit-image {
            height: 100vh;
            background-size: auto 100vh;
            background-position: center center;
        }
        .orbit-caption {
            background-color: transparent;
            bottom: 15%;
            .orbit-info {
                background-color: rgba($black, .65);
                padding: $global-padding * 2;
                border-radius: $global-radius;
                p {
                    margin-bottom: 0;
                }
            }
            .button {
                margin-bottom: 0px;
                font-size: $global-font-size * 1.25;
                opacity: .85;
            }
        }
    }
}

.copyright {
    text-align: right;
}

@media screen and (max-width: 39.9375em) {
    .orbit {
        &.append {
            .orbit-caption {
                bottom: 20%;
                padding: $global-padding * 0.65;
                .orbit-info {
                    padding: $global-padding * 1.25;
                    h2,
                    p {
                        line-height: 1.2;
                    }
                }
                .button {
                    margin-bottom: 0;
                    font-size: $global-font-size;
                    opacity: .85;
                }
                .grid-container {
                    padding: 0;
                }
            }
        }
    }
    .copyright {
        text-align: center;
        font-size: $global-font-size * 0.85;
    }
}

.page-top,
.header-bg {
    background-color: $black;
    padding-top: $global-padding;
    background-size: 100% auto;
    background-position: center;
}

header {
    &.append {
        margin-bottom: $global-margin*2;
        .top-bar {
            background: transparent;
            padding: 0;
            .logo {
                background-color: $header-background;
                border-radius: $global-radius;
                margin-right: $global-margin/16;
                padding: 0 $global-padding*2;
                height: 96px;
                display: block;
                img {
                    max-height: 96px;
                    width: auto;
                }
            }
        }
        .title-bar {
            background-color: $header-background;
            border-radius: $global-radius;
        }
        i.atSearch {
            height: 57px;
            background-color: $header-background;
            padding: $global-padding;
            display: block;
            font-size: $global-font-size * 1.8;
            border-radius: $global-radius;
        }
        [type=text] {
            height: 57px;
            margin-bottom: $global-margin/16;
            border-radius: $global-radius;
            background-color: $header-background;
            border: none;
            box-shadow: none;
            max-width: 100%;
        }
        .menu {
            margin-bottom: 0;
            border-radius: $global-radius;
            a {
                color: $black;
                background-color: $header-background;
                margin-right: $global-margin/16;
                padding: 0 $global-padding;
                padding: $global-margin*0.7;
                transition: 0.2s;
                &:hover {
                    color: $autostar;
                }
            }
        }
    }
}

.blackCover {
    padding-top: $global-padding;
    background: -moz-linear-gradient(top, rgba(28, 28, 28, 0.65) 0%, rgba(28, 28, 28, 1) 100%);
    background: -webkit-linear-gradient(top, rgba(28, 28, 28, 0.65) 0%, rgba(28, 28, 28, 1) 100%);
    background: linear-gradient(to bottom, rgba(28, 28, 28, 0.65) 0%, rgba(28, 28, 28, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a61c1c1c', endColorstr='#1c1c1c', GradientType=0);
    nav {
        display: inline-block;
    }
    .breadcrumbs li {
        color: $white;
        float: none;
        display: inline-block;
    }
}

.page-title {
    margin: $global-margin*2 0;
    color: $white;
    display: inline-block;
    border-bottom: 2px solid $primary-color;
    text-shadow: 0 1px 4px rgba(0, 0, 0, .65);
    font-weight: 700;
}

@media screen and (max-width: 39.9375em) {
    .page-top {
        background-position: top center;
        background-repeat: no-repeat;
        padding-top: 0.65rem;
        background-size: auto 100%;
    }
    header {
        &.append {
            position: fixed;
            z-index: 1;
            margin-bottom: $global-margin;
            .top-bar {
                box-shadow: $auto-shadow;
                max-height: 80vh;
                overflow: scroll;
                border-radius: $global-radius;
            }
            .title-bar {
                margin-bottom: $global-margin/16;
                background-color: transparent;
                padding: 0;
                .logo {
                    padding: 0 $global-margin*1.5;
                    background-color: $header-background;
                    display: block;
                    border-radius: $global-radius;
                    box-shadow: $auto-shadow;
                    img {
                        max-height: 64px;
                        width: auto;
                    }
                }
                .button {
                    margin-bottom: 0;
                    height: 64px;
                    box-shadow: $auto-shadow;
                }
            }
            .is-drilldown {
                background-color: $white;
            }
        }
    }
    .blackCover {
        margin-top: $global-margin*5;
    }
    .page-title {
        margin: $global-margin 0;
    }
}

.page-body {
    background-color: $white;
    padding: $global-padding*2;
    box-shadow: 4px 4px 0 rgba($autostar, 0.4);
    border-radius: $global-radius;
}

.addRabbit {
    background-image: url('../img/pageBodyBg.png');
    background-position: 0 100px;
}

.zoom {
    @include allShadow($black);
    margin-bottom: $global-margin;
    img {
        display: block;
        &::selection {
            background-color: transparent;
        }
    }
}

.productThumbnail {
    margin-bottom: $global-margin;
    img {
        @include allShadow($black);
        width: 100%;
        margin-bottom: $global-margin;
        &.active {
            border-color: $autostar;
            border-width: 2px;
        }
    }
    button {
        cursor: pointer;
    }
}

.news-item {
    margin-bottom: $global-margin;
    .news-title {
        border-top: 1px dashed $autostar;
        padding-top: $global-padding;
        font-weight: 700;
    }
    .news-time {
        border: 1px solid $autostar;
        span {
            display: block;
            padding: $global-padding/2;
            text-align: center;
            &.month {
                background-color: $autostarMedium;
            }
        }
    }
    img {
        max-width: 100%;
        height: auto;
        @include allShadow($black);
    }
}

.company-info {
    .company-name {
        font-size: $global-font-size * 1.3;
        color: $autostar;
    }
    li {
        // background-color: $white;
        margin-bottom: 1px;
        padding-bottom: $global-padding/2;
        i {
            font-size: $global-font-size * 2;
            height: auto;
            margin-right: $global-margin;
        }
    }
    .qrcode {
        @include allShadow($black);
    }
}

@media screen and (max-width: 39.9375em) {
    .page-body {
        padding: 1.25rem;
    }
    .news-item {
        .news-time {
            border: none;
            color: $autostar;
        }
    }
}

footer {
    &.append {
        color: $white;
        text-shadow: 0 0 4px $black;
        font-weight: bold;
        .grid-x {
            width: auto;
            color: $white;
            padding-top: $global-padding;
            padding-bottom: $global-padding;
        }
        hr {
            margin: 0;
            border-width: 3px;
        }
    }
    &.atHome {
        color: $white;
        text-shadow: 0 0 4px $black;
        font-weight: bold;
    }
}

@media screen and (max-width: 39.9375em) {
    footer {
        &.append {
            .grid-x {
                padding-top: 0.65rem;
                padding-bottom: 0.65rem;
            }
        }
    }
}

.productCategory {
    // border: 1px solid $autostarLight;
    .is-dropdown-submenu {
        border-color: $autostarLight;
    }
    a {
        color: $black;
        border-bottom: 1px solid $autostarLight;
        &:hover {
            color: $autostar;
        }
    }
}

.view-list {
    .card {
        display: block;
        border-color: $medium-gray;
        &:hover {
            border-color: $primary-color;
        }
    }
    .productImg {
        float: left;
        width: 25%;
        background-color:$white;
        .areaImg {
            width: 100%;
            padding-bottom: 100%;
            height: 0;
            background-position: center;
            background-repeat: no-repeat;
            background-size: 100%;
            background-image: url('../img/no-image.png');
        }
    }
    .card-section {
        float: left;
        width: 75%;
    }
}

.view-grid {
    .card {
        display: block;
        border-color: $medium-gray;
        &:hover {
            border-color: $primary-color;
        }
    }
    .productImg {
        background-color:$white;
        .areaImg {
            width: 100%;
            padding-bottom: 100%;
            height: 0;
            background-position: center;
            background-repeat: no-repeat;
            background-size: 100%;
            background-image: url('../img/no-image.png');
        }
    }
    .card-section {
        .title {
            font-size: $global-font-size;
        }
        .info {
            display: none;
        }
    }
}

.formAppend {
    [type=text],
    textarea,
    select,
    #tel {
        background-color: transparent;
        border-width: 0 0 1px 0;
        box-shadow: none;
        margin-bottom: 2rem;
        border-radius: 0;
        &:focus {
            border-bottom-color: $primary-color;
        }
    }
    .intl-tel-input {
        display: block;
    }
}

.tableProperty {
    .title {
        width: 50%;
        border-bottom: 1px solid $medium-gray;
        color: $dark-gray;
    }
    td {
        border-bottom: 1px solid $dark-gray;
        background-color: $light-gray;
    }
}

.revealMenu {
    ul {
        list-style: none;
        li a {
            border-bottom: 1px solid #EAD6A2;
            padding: 0.5rem 1rem;
            display: block;
        }
        &.first {
            margin-left: 0;
        }
    }
}

.orbit-info {
    .title {
        color: $autostar;
    }
}

.orbit-bullets {
    &.append {
        button {
            background-color: transparent;
            border: 1px solid $autostar;
            &.is-active {
                background-color: $autostar;
            }
        }
    }
}

.aboutUsImg {
    img {
        margin-bottom: $global-margin;
        @include allShadow($black);
    }
}

.productInfo {
    .title{
        color: $autostar;
        border-bottom: 2px solid $autostar;
        font-size: $global-font-size;
        display: inline-block;
    }
    table {
        border:1px solid $light-gray;
    }
}