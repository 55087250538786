@font-face {
  font-family: 'freeline';
  src:  url('components/freeline-icons/freeline.eot?mahseu');
  src:  url('components/freeline-icons/freeline.eot?mahseu#iefix') format('embedded-opentype'),
    url('components/freeline-icons/freeline.ttf?mahseu') format('truetype'),
    url('components/freeline-icons/freeline.woff?mahseu') format('woff'),
    url('components/freeline-icons/freeline.svg?mahseu#freeline') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="freeline-"], [class*=" freeline-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'freeline' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.freeline-add:before {
  content: "\e900";
}
.freeline-alarm:before {
  content: "\e901";
}
.freeline-album:before {
  content: "\e902";
}
.freeline-arrow-left:before {
  content: "\e903";
}
.freeline-arrow-right:before {
  content: "\e904";
}
.freeline-award:before {
  content: "\e905";
}
.freeline-bell:before {
  content: "\e906";
}
.freeline-bubble:before {
  content: "\e907";
}
.freeline-camera:before {
  content: "\e908";
}
.freeline-cascade:before {
  content: "\e909";
}
.freeline-check:before {
  content: "\e90a";
}
.freeline-cloud:before {
  content: "\e90b";
}
.freeline-coffee:before {
  content: "\e90c";
}
.freeline-compass:before {
  content: "\e90d";
}
.freeline-dashboard:before {
  content: "\e90e";
}
.freeline-delete:before {
  content: "\e90f";
}
.freeline-download:before {
  content: "\e910";
}
.freeline-eye:before {
  content: "\e911";
}
.freeline-folder:before {
  content: "\e912";
}
.freeline-heart:before {
  content: "\e913";
}
.freeline-home:before {
  content: "\e914";
}
.freeline-location:before {
  content: "\e915";
}
.freeline-lock:before {
  content: "\e916";
}
.freeline-mail:before {
  content: "\e917";
}
.freeline-minus:before {
  content: "\e918";
}
.freeline-pencil:before {
  content: "\e919";
}
.freeline-phone:before {
  content: "\e91a";
}
.freeline-print:before {
  content: "\e91b";
}
.freeline-search:before {
  content: "\e91c";
}
.freeline-setting:before {
  content: "\e91d";
}
.freeline-star:before {
  content: "\e91e";
}
.freeline-upload:before {
  content: "\e91f";
}
.freeline-user:before {
  content: "\e920";
}
